import axios from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Statistic, Row, Col, Card } from 'antd'
import Layout from '../layout/Layout'
import { CustomSearch, CustomSpinner, CustomTable } from '../utils/CustomComponents'
import CountUp from 'react-countup'
import { TruckFilled } from '@ant-design/icons'
const { Text, Title } = Typography

const LogisticsReportMilesPerTruck = () => { 
    const [loading, setLoading] = useState(true)
    const [inspectionList, setInspectionList] = useState([])
    const [totalMiles, setTotalMiles] = useState(0)
    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: 'Truck',
            dataIndex: 'truck',
            key: 'truck',
            render: (value) => {
                return <Text strong>{value}</Text>
            }
        },
        {
            title: 'Miles',
            dataIndex: 'miles',
            key: 'miles',
            render: (value) => {
                return <Statistic title="" value={value} precision={0} valueStyle={{fontSize: 14}} />
            }
        },
    ]

    useEffect(() => {
        getInspectionList()
    }, [startDate, endDate])

    const getInspectionList = async() => {
        setLoading(true)
        const start = `${startDate.year()}-${startDate.month() + 1}-${startDate.date()}`
        const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`

        const url = api + `/inspections/logistics/date?startDate=${start}&endDate=${end}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            let drivers = new Map()

            data.map((item) => {
                if(item.truck){
                    const collection = drivers.get(item.truck);
                    if (!collection) {
                        drivers.set(item.truck, [item]);
                    } else {
                        collection.push(item);
                    }
                }
            })

            let miles = 0

            drivers.forEach((values, key) => {
                let accum = 0
                values.map((item) => {
                    if(item.post){
                        accum += (item.post.mileageEnding - item.mileageBegin)
                    }
                })
                miles += accum
                list.push({
                    id: key,
                    key: key,
                    truck: values[0].truck,
                    miles: accum
                })
            })
            let sortedData = list.sort(function(a, b) {
                return a.truck.localeCompare(b.truck, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                })
            })
            setTotalMiles(miles)
            setInspectionList(sortedData)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }     

    const onRangeChange = (dates) => {
        if (dates) {
            setStartDate(dates[0])
            setEndDate(dates[1])
        }
    }

    const RenderDates = () => {
        return <CustomSearch 
        loading={loading}
        startDate={startDate}
        endDate={endDate}
        dateFormat={'MM/DD/YYYY'}
        onRangeChange={onRangeChange}
        />
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'}>
            <CustomTable 
            columns={columns}
            data={inspectionList}
            isScroll={false}
            />
        </Flex>
    }

    const formatter = (value) => <CountUp end={value} separator="," duration={2} />

    const RenderCard = () => {
        return <Row gutter={16}>
        <Col span={6}>
            <Card bordered={false}>
                <Title level={2}>Total Miles</Title>
                <Row gutter={16}>
                    <Col span={12}>
                        <Statistic
                        title="Miles"
                        value={totalMiles}
                        valueStyle={{
                            color: '#3f8600',
                        }}
                        prefix={<TruckFilled />}
                        formatter={formatter}
                        />
                    </Col>
                </Row>
            </Card>
        </Col>
        </Row>
    }

    return (
        <Layout
            title='Miles Per Truck'
            description='Logistics Report'
        >
            <Flex vertical gap={'large'}>
                <RenderDates />
                <RenderCard />
                {
                    loading ? 
                    <CustomSpinner/> 
                    : 
                    <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default LogisticsReportMilesPerTruck